<template>
  <div class="row">
    <div class="col-12 p-0 text-center">
      <div class="spinner-grow" style="color: #d02a4b" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow mx-3" style="color: #2b4fb0" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow" style="color: #fff" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

