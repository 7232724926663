<template>
	<footer :class="bg" >
		<div class="container">
			<div class="row pb-0 pt-5">
				<div class="col-12 col-lg-4 col-xl-6">
					<div class="row">
						<router-link to="/" :class="isVisible">
							<img v-if="isApp == '/app/'" class="col-8 img-fluid" :src="require('@/assets/logo-blue.png')" alt="Serbian Researchers logo" style="min-width:240px"  width="100" height="59">
							<img v-else class="col-6 img-fluid" :src="require('@/assets/logo.png')" alt="Serbian Researchers logo" width="240" height="58">
						</router-link>

						<p class="col-12 text_m text_400 mt-3 mt-lg-5">
							Copyright © 2021 - {{ currentYear}} Serbian Researchers <span class="d-none d-lg-inline-block">| Sva prava zadržana.</span>
						</p>
					</div>
				</div>
				<div class="col-12 col-lg-8 col-xl-6">
					<div class="row">
						<div class="col-6 col-lg-4 text_m text_400" :class="isVisible">
							<p class="title_s text_400">Platforma</p>
							<p class="mb-2"><router-link to="/istrazivaci">Istraživači</router-link></p>
							<p class="mb-2"><router-link to="/o-platformi">O Platformi</router-link></p>
							<p class="mb-2"><router-link to="/blog">Blog</router-link></p>
						</div>
						<div class="col-6 col-lg-4 text_m text_400" :class="isVisible">
							<p class="title_s text_400">Privatnost</p>
							<p class="mb-2"><router-link to="/terms-and-conditions">Uslovi korišćenja</router-link></p>
							<p class="mb-2"><router-link to="/privacy-policy">Politika privatnosti</router-link></p>
							<p class="mb-2"><router-link to="/cookie-policy">Politika kolačića</router-link></p>
						</div>
						<div class="col-12 col-lg-4 text_m text_400 mt-4 mt-lg-0" :class="isVisible">
							<p class="title_s text_400">Kontakt</p>
							<p class="mb-2"><router-link to="/faq">Česta pitanja</router-link></p>
							<p>
								<a href='https://www.linkedin.com/company/srb-researchers' target="_blank">
									<img :src="require('@/assets/social/linkedin.svg')" style='max-width: 20px'>
								</a>
								<a href='https://www.instagram.com/srb_researchers/' class="px-2" target="_blank">
									<img :src="require('@/assets/social/instagram.png')" style='max-width: 20px'>
								</a>
								<a href='https://www.facebook.com/serbianresearchers' target="_blank">
									<img :src="require('@/assets/social/facebook.png')" style='max-width: 20px'>
								</a>
								<a href='https://twitter.com/srb_researchers' target="_blank" class="px-2">
									<img :src="require('@/assets/social/twitter.png')" style='max-width: 20px'>
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
  name: 'Footer',
  props: ['bg', 'isVisible'],
  computed: {
    currentYear(){
      const currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      return currentYear;
    }
  },
	data() {
		return {
			isApp: this.$route.path.slice(0,5),
			currentDate: ''
		}
	},

};
</script>



