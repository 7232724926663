<template>
  <nav class="navbar navbar-expand-lg navbar-dark py-3">
    <div class="container d-flex justify-content-between p-0">

      <router-link class="navbar-brand logo" to="/">
        <img v-if="logoColor" class="img-fluid" :src="require('@/assets/logo-blue.svg')" alt="Logo" width="150">
        <img v-else class="img-fluid" :src="require('@/assets/logo.png')" alt="Logo" width="150">
      </router-link>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sr-menu"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="sr-menu">
        <ul class="navbar-nav ml-auto mb-2 mb-lg-n2">
          <li class="nav-item pl-0 pr-lg-3">
            <router-link class="nav-link text_bold text_xl" to="/istrazivaci/">Istraživači</router-link>
          </li>
          <li class="nav-item pl-0 pr-lg-3">
            <router-link class="nav-link text_bold text_xl" to="/o-platformi/">O Platformi</router-link>
          </li>
          <li class="nav-item pl-0 pr-lg-4">
            <router-link class="nav-link text_bold text_xl" to="/blog">Blog</router-link>
          </li>
        </ul>
      </div>

    </div>
  </nav>
</template>

<script>
export default {
	name: 'Navbar',
	props: ['link', 'logoColor'],
};
</script>


